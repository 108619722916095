<template>
	<main class="container-wide">
		<div class="flex space-between mb-40">
			<div>
				<h1 class="mb-10">Support</h1>

				<p class="color-grey">Yvirlit yvir support terminalar og terminalar, sum eru fyribils á eini avtalu.</p>
			</div>
		</div>

		<div class="support-tabs" style="margin-bottom: 32px">
			<a href="#" @click.prevent class="support-tab" :class="{ active: activeTab == 'Support terminalar' }" @click="activeTab = 'Support terminalar'">
				Support terminalar
			</a>

			<a href="#" @click.prevent class="support-tab" :class="{ active: activeTab == 'Fyribils á avtalu' }" @click="activeTab = 'Fyribils á avtalu'">
				Fyribils á avtalu
			</a>
		</div>

		<div class="flex justify-center" v-if="loading">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<template v-if="activeTab == 'Support terminalar'">
			<div v-if="!loading && !supportTerminals.length" class="text-grey my-50 text-center">Ongin support terminalur er skrásettur</div>

			<div v-if="!loading && supportTerminals.length" class="table-container">
				<table>
					<thead>
						<th class="whitespace-nowrap">Sáttmáli</th>

						<th class="whitespace-nowrap">Verifone SR</th>

						<th class="whitespace-nowrap">Verifone NR</th>

						<th class="whitespace-nowrap">Seinasti loggur</th>
					</thead>

					<tbody>
						<tr v-for="(terminal, index) in supportTerminals" :key="`terminal-${index}`">
							<td class="whitespace-nowrap">
								<div v-if="getCustomer(terminal)">
									<router-link :to="{ name: 'AgreementShow', params: { id: getTerminalAgreement(terminal).id } }" class="hover:color-blue">
										{{ getCustomer(terminal).name }}
									</router-link>
								</div>

								<div v-if="!getCustomer(terminal)">-</div>
							</td>

							<td class="whitespace-nowrap">
								<a href="#" @click.prevent="showTerminal(terminal.id)" class="hover:color-blue">
									{{ terminal.verifoneSerialnumber }}
								</a>
							</td>

							<td class="whitespace-nowrap">
								<a href="#" @click.prevent="showTerminal(terminal.id)" class="hover:color-blue">
									{{ terminal.verifoneNumber }}
								</a>
							</td>

							<td>{{ getLatestLog(terminal) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>

		<template v-if="activeTab == 'Fyribils á avtalu'">
			<div v-if="!loading && !temporaryTerminals.length" class="text-grey my-50 text-center">Ongin fyribils terminalur er skrásettur</div>

			<div v-if="!loading && temporaryTerminals.length" class="table-container">
				<table>
					<thead>
						<th class="whitespace-nowrap">Sáttmáli</th>

						<th class="whitespace-nowrap">Verifone SR</th>

						<th class="whitespace-nowrap">Verifone NR</th>

						<th class="whitespace-nowrap">Seinasti loggur</th>
					</thead>

					<tbody>
						<tr v-for="(terminal, index) in temporaryTerminals" :key="`terminal-${index}`">
							<td class="whitespace-nowrap">
								<div v-if="getCustomer(terminal)">
									<router-link :to="{ name: 'AgreementShow', params: { id: getTerminalAgreement(terminal).id } }" class="hover:color-blue">
										{{ getCustomer(terminal).name }}
									</router-link>
								</div>

								<div v-if="!getCustomer(terminal)">-</div>
							</td>

							<td class="whitespace-nowrap">
								<a href="#" @click.prevent="showTerminal(terminal.id)" class="hover:color-blue">
									{{ terminal.verifoneSerialnumber }}
								</a>
							</td>

							<td class="whitespace-nowrap">
								<a href="#" @click.prevent="showTerminal(terminal.id)" class="hover:color-blue">
									{{ terminal.verifoneNumber }}
								</a>
							</td>

							<td>{{ getLatestLog(terminal) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</main>
</template>

<script>
import axios from 'axios';

export default {
	name: 'Terminals',

	data() {
		return {
			loading: true,
			terminals: [],
			activeTab: 'Support terminalar',
		};
	},

	mounted() {
		axios
			.get('/terminals')
			.then((response) => {
				this.terminals = response.data;
			})
			.catch((error) => {
				console.log('Error fetching terminals', error);
			})
			.finally(() => {
				this.loading = false;
			});
	},

	computed: {
		supportTerminals() {
			return this.terminals.filter((terminal) => {
				return terminal.isSupport;
			});
		},

		temporaryTerminals() {
			return this.terminals.filter((terminal) => {
				return terminal.isTemporarilyOnAgreement;
			});
		},
	},

	methods: {
		getLatestLog(terminal) {
			const filteredLogs = terminal.logs.filter((log) => {
				return log.userName != '[System]';
			});

			if (!filteredLogs.length) {
				return '';
			}

			return filteredLogs[filteredLogs.length - 1]?.comments;
		},

		getTerminalAgreement(terminal) {
			const terminalAgreements = terminal.agreementTerminals;

			const lastTerminalAgreement = terminalAgreements[terminalAgreements.length - 1];

			if (!lastTerminalAgreement) {
				return null;
			}

			return lastTerminalAgreement.agreement;
		},

		getCustomer(terminal) {
			const terminalAgreement = this.getTerminalAgreement(terminal);

			if (!terminalAgreement) {
				return null;
			}

			return terminalAgreement.customer;
		},

		showTerminal(terminalId) {
			this.$router.push({ name: 'TerminalShow', params: { id: terminalId } });
		},
	},
};
</script>

<style lang="scss" scoped>
.table-container {
	overflow-x: auto;
}

.support-tabs {
	display: flex;

	border-bottom: 1px solid #e8e8e8;

	.support-tab {
		margin-left: 4px;
		padding: 12px 20px;

		color: #333;
		background: white;

		font-size: 16px;
		font-weight: bold;

		border-radius: 8px 8px 0 0;

		transition: 0.25s all ease-in-out;

		&.active {
			color: white;
			background: var(--color-blue);
		}
	}
}
</style>
